export default {
  BUTTON_PRIMARY_CLASS: 'cm_button button button--primary',
  BUTTON_SECONDARY_CLASS: 'cm_button button button--secondary',

  FACET_DIALOG_BUTTON_CLASS: 'cm_btn',
  SHOW_ALL_BUTTON_CLASS: 'cm_btn',

  SEARCH_BOX_INPUT_CLASS: 'search__input field__input',
  SEARCH_BOX_PLACEHOLDER: 'Search here...',
  SEARCH_BOX_CLEAR_BUTTON: '✕',

  RELATED_PRODUCT_CONTAINER_CLASS: 'product-grid-container related-products',

  SELECT_YOUR_VEHICLE: 'SELECT YOUR RIDE',
  SELECT_YOUR_VEHICLE_BLOCK: 'SELECT YOUR RIDE',
  VERIFY_FITMENT_TITLE: 'VERIFY FITMENT WITH YOUR RIDE',
  YOU_MAY_ALSO_LIKE: 'RELATED PRODUCTS',
};
