import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

const defaultSearchBox = shopifyDawnGenericDefaults.Widgets.find((widget) => widget.name === 'SearchBox');
const defaultSearchHeader = shopifyDawnGenericDefaults.Widgets.find(
  (widget) => widget.name === 'SearchHeader',
);

function handleCartVehicleLabel(vehicle) {
  const cartVehicleLabel = window.document.querySelector('#cm_cart-vehicle-label');
  if (cartVehicleLabel) {
    cartVehicleLabel.querySelector('.vehicle').textContent = Object.values(vehicle).join(' ');
    cartVehicleLabel.removeAttribute('style');
  }
}

function AfterInit() {
  const currentVehicle = window.Convermax.getVehicle();
  if (Object.keys(currentVehicle).length) {
    handleCartVehicleLabel(currentVehicle);
  }
}

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
  AfterInit,
  Widgets: [
    ...shopifyDawnGenericDefaults.Widgets.filter((widget) => !['SearchHeader'].includes(widget.name)),
    {
      name: 'HeaderSearchBox',
      type: 'SearchBox',
      location: {
        selector: '#header__search_1 .search-modal__content',
        class: 'cm_search-box-root__home',
      },
      template: 'SearchBox',
      onDropdownItemsReceived: () => defaultSearchBox.onDropdownItemsReceived,
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      ...defaultSearchHeader,
      sortEntries: {
        ...defaultSearchHeader.sortEntries,
        'order_count:desc': 'Most Popular',
      },
    },
  ],
};
